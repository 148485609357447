import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import styles from './Login.module.css';
import logo from '../assets/Logo-PNG-1.webp';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { signIn } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await signIn(email, password);
    } catch (error) {
      setError('Invalid email or password');
      console.error('Error signing in:', error.message);
    }
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.logoContainer}>
          <img 
            src={logo} 
            alt="FC Cost Logo" 
            className={styles.logo}
          />
          <h1 className={styles.title}>FC Cost</h1>
        </div>
        
        {error && <div className={styles.error}>{error}</div>}
        
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
        />
        <button type="submit">Sign In</button>
        
        <div className={styles.poweredBy}>
          Powered by <a href="https://uniumbra.com" target="_blank" rel="noopener noreferrer">UniUmbra</a>
        </div>
      </form>
    </div>
  );
};

export default Login;
