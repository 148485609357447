import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../config/supabaseClient';

export const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  const fetchUsers = async () => {
    const { data, error } = await supabase
      .from('user')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) {
      setError('Error fetching users');
      return;
    }
    setUsers(data);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return { users, error, refetchUsers: fetchUsers };
};

export const useActivities = (filterUser, filterDate, dateRange) => {
  const [activities, setActivities] = useState([]);
  const [error, setError] = useState(null);

  const fetchActivities = useCallback(async () => {
    let query = supabase
      .from('user_activity_log')
      .select(`
        *,
        user:user_id(username, email)
      `)
      .order('action_timestamp', { ascending: false });

    if (filterUser !== 'all') {
      query = query.eq('user_id', filterUser);
    }

    if (filterDate === 'custom' && dateRange.start && dateRange.end) {
      query = query
        .gte('action_timestamp', dateRange.start)
        .lte('action_timestamp', dateRange.end);
    } else if (filterDate === 'today') {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      query = query.gte('action_timestamp', today.toISOString());
    } else if (filterDate === 'week') {
      const weekAgo = new Date();
      weekAgo.setDate(weekAgo.getDate() - 7);
      query = query.gte('action_timestamp', weekAgo.toISOString());
    }

    const { data, error } = await query;

    if (error) {
      setError('Error fetching activities');
      return;
    }
    setActivities(data);
  }, [filterUser, filterDate, dateRange]);

  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  return { activities, error, refetchActivities: fetchActivities };
};
