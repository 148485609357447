import React from 'react';
import styles from './Users.module.css';

const ActivityItem = ({ activity }) => (
  <div className={styles.activityItem}>
    <div className={styles.activityInfo}>
      <div className={styles.activityType}>{activity.action_type}</div>
      <div className={styles.activityMeta}>
        {activity.user?.username} ({activity.user?.email})
      </div>
    </div>
    <div className={styles.activityTime}>
      {new Date(activity.action_timestamp).toLocaleString()}
    </div>
  </div>
);

export default ActivityItem;
