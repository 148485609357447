import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useUsers, useActivities } from '../hooks/useUsers';
import ActivityItem from './ActivityItem';
import AddUserModal from './AddUserModal';
import UserListModal from './UserListModal';
import styles from './Users.module.css';

const Users = () => {
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUserListModal, setShowUserListModal] = useState(false);
  const [activeRole, setActiveRole] = useState('admin');
  const [filterUser, setFilterUser] = useState('all');
  const [filterDate, setFilterDate] = useState('all');
  const [dateRange, setDateRange] = useState({
    start: '',
    end: ''
  });

  const { users, refetchUsers } = useUsers();
  const { activities } = useActivities(filterUser, filterDate, dateRange);

  useEffect(() => {
    if (currentUser?.role !== 'admin') {
      navigate('/');
    }
  }, [currentUser, navigate]);

  const groupedUsers = {
    admin: users.filter(user => user.role === 'admin'),
    user: users.filter(user => user.role === 'user')
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>User Management</h2>
        <div className={styles.headerRight}>
          <button 
            className={styles.viewButton} 
            onClick={() => {
              setActiveRole('admin');
              setShowUserListModal(true);
            }}
          >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
              <circle cx="9" cy="7" r="4"></circle>
              <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
            <span className={styles.buttonText}>Administrators</span>
          </button>
          <button 
            className={styles.viewButton} 
            onClick={() => {
              setActiveRole('user');
              setShowUserListModal(true);
            }}
          >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
            <span className={styles.buttonText}>Users</span>
          </button>
          <button className={styles.addButton} onClick={() => setShowAddModal(true)}>
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
            <span className={styles.buttonText}>Add User</span>
          </button>
        </div>
      </div>

      <div className={styles.activitySection}>
        
        <div className={styles.filterBar}>
          <select
            value={filterUser}
            onChange={(e) => setFilterUser(e.target.value)}
            className={styles.filterSelect}
          >
            <option value="all">All Users</option>
            {users.map(user => (
              <option key={user.id} value={user.id}>{user.username}</option>
            ))}
          </select>

          <select
            value={filterDate}
            onChange={(e) => setFilterDate(e.target.value)}
            className={styles.filterSelect}
          >
            <option value="all">All Time</option>
            <option value="today">Today</option>
            <option value="week">Last 7 Days</option>
            <option value="custom">Custom Range</option>
          </select>

          {filterDate === 'custom' && (
            <>
              <input
                type="date"
                value={dateRange.start}
                onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
                className={styles.dateInput}
              />
              <input
                type="date"
                value={dateRange.end}
                onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
                className={styles.dateInput}
              />
            </>
          )}
        </div>

        <div className={styles.activityList}>
          {activities.map(activity => (
            <ActivityItem key={activity.id} activity={activity} />
          ))}
        </div>
      </div>

      {showAddModal && (
        <AddUserModal
          closeModal={() => setShowAddModal(false)}
          refreshUsers={refetchUsers}
        />
      )}

      {showUserListModal && (
        <UserListModal
          users={groupedUsers[activeRole]}
          title={activeRole === 'admin' ? 'Administrators' : 'Users'}
          closeModal={() => setShowUserListModal(false)}
          refreshUsers={refetchUsers}
        />
      )}
    </div>
  );
};

export default Users;
