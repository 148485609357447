import React, { useState, useRef, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import styles from './Layout.module.css';
import logo from '../assets/Logo-PNG-1.webp';

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, signOut } = useAuth();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const isDashboard = location.pathname === '/';

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.leftSection}>
            <div className={styles.backButtonContainer}>
              {!isDashboard ? (
                <button onClick={() => navigate('/')} className={styles.iconButton}>
                  <svg viewBox="0 0 24 24" className={styles.buttonIcon}>
                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
                  </svg>
                </button>
              ) : (
                <div className={styles.backButtonPlaceholder} />
              )}
            </div>
            <div className={styles.greeting}>
              Hi, {user?.username || 'User'}
            </div>
          </div>
          <div className={styles.logoWrapper} onClick={() => navigate('/')}>
            <img src={logo} alt="FC Cost Logo" className={styles.logo} />
          </div>
          <div className={styles.actionButtons}>
            {user?.role === 'admin' && (
              <div className={styles.dropdownContainer} ref={dropdownRef}>
                <button 
                  className={styles.dropdownToggle}
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <svg viewBox="0 0 24 24" className={styles.buttonIcon}>
                    <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                  </svg>
                </button>
                {showDropdown && (
                  <div className={styles.dropdownMenu}>
                    <button onClick={() => { navigate('/'); setShowDropdown(false); }}>
                      <svg viewBox="0 0 24 24" className={styles.menuIcon}>
                        <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
                      </svg>
                      Dashboard
                    </button>
                    <button onClick={() => { navigate('/users'); setShowDropdown(false); }}>
                      <svg viewBox="0 0 24 24" className={styles.menuIcon}>
                        <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                      </svg>
                      Users
                    </button>
                    <button onClick={() => { navigate('/contacts'); setShowDropdown(false); }}>
                      <svg viewBox="0 0 24 24" className={styles.menuIcon}>
                        <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V6h16v12zM4 0h16v2H4zm0 22h16v2H4zm8-10a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0 2c-2.03 0-6 1.17-6 3.5V17h12v-1.5c0-2.33-3.97-3.5-6-3.5z" />
                      </svg>
                      Contacts
                    </button>
                    <button onClick={() => { navigate('/items'); setShowDropdown(false); }}>
                      <svg viewBox="0 0 24 24" className={styles.menuIcon}>
                        <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zm-7-2h2V7h-4v2h2z" />
                      </svg>
                      Items
                    </button>
                  </div>
                )}
              </div>
            )}
            <button onClick={handleLogout} className={styles.iconButton}>
              <svg viewBox="0 0 24 24" className={styles.buttonIcon}>
                <path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z" />
              </svg>
            </button>
          </div>
        </div>
      </header>
      <main className={styles.main}>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
