import React, { useState } from 'react';
import { supabase, supabaseAdmin } from '../config/supabaseClient';
import ChangePasswordModal from './ChangePasswordModal';
import styles from './Users.module.css';

const UserListModal = ({ users, title, closeModal, refreshUsers }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleDelete = async (user) => {
    if (window.confirm(`Are you sure you want to delete ${user.username}?`)) {
      try {
        // Delete from auth
        const { error: authError } = await supabaseAdmin.auth.admin.deleteUser(
          user.auth_id
        );
        if (authError) throw authError;

        // Delete from user table
        const { error: dbError } = await supabase
          .from('user')
          .delete()
          .eq('id', user.id);
        if (dbError) throw dbError;

        setSuccess(`${user.username} has been deleted successfully`);
        refreshUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
        setError(error.message);
      }
    }
  };

  const handleChangePassword = (user) => {
    setSelectedUser(user);
    setShowChangePassword(true);
  };

  return (
    <>
      <div className={styles.modal} onClick={closeModal}>
        <div
          className={styles.modalContent}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.modalHeader}>
            <h2 className={styles.sectionTitle}>{title}</h2>
            <button className={styles.closeButton} onClick={closeModal}>
              ×
            </button>
          </div>
          {error && <div className={styles.error}>{error}</div>}
          {success && <div className={styles.success}>{success}</div>}
          <div className={styles.userList}>
            {users.map(user => (
              <div key={user.id} className={styles.userListItem}>
                <div className={styles.userListInfo}>
                  <div className={styles.userName}>{user.username}</div>
                  <div className={styles.userEmail}>{user.email}</div>
                </div>
                <div className={styles.userActions}>
                  <button
                    className={`${styles.actionButton} ${styles.changePasswordButton}`}
                    onClick={() => handleChangePassword(user)}
                    title="Change Password"
                  >
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                      <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                      <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                    </svg>
                  </button>
                  <button
                    className={`${styles.actionButton} ${styles.deleteButton}`}
                    onClick={() => handleDelete(user)}
                    title="Delete User"
                  >
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                      <path d="M3 6h18"></path>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6"></path>
                      <path d="M8 6V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    </svg>
                  </button>
                </div>
                <span className={`${styles.role} ${user.role === 'admin' ? styles.roleAdmin : styles.roleUser}`}>
                  {user.role}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showChangePassword && selectedUser && (
        <ChangePasswordModal
          user={selectedUser}
          closeModal={() => {
            setShowChangePassword(false);
            setSelectedUser(null);
          }}
          onSuccess={() => {
            setSuccess('Password updated successfully');
            refreshUsers();
          }}
        />
      )}
    </>
  );
};

export default UserListModal;
