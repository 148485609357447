import React from 'react';
import styles from './Items.module.css';

const Items = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>Items Management</h2>
      </div>

      <div className={styles.contentSection}>
        <div className={styles.placeholderCard}>
          <div className={styles.placeholderIcon}>📦</div>
          <h3>Items Management</h3>
          <p>This section will be used to manage your items and inventory</p>
        </div>
      </div>
    </div>
  );
};

export default Items;
