import React, { useState } from 'react';
import { supabaseAdmin } from '../config/supabaseClient';
import styles from './Users.module.css';

const ChangePasswordModal = ({ user, closeModal, onSuccess }) => {
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!newPassword) {
      setError('Please enter a new password');
      return;
    }

    try {
      const { error: updateError } = await supabaseAdmin.auth.admin.updateUserById(
        user.auth_id,
        { password: newPassword }
      );

      if (updateError) throw updateError;

      setSuccess('Password updated successfully');
      onSuccess?.();
      setTimeout(() => {
        closeModal();
      }, 1500);
    } catch (error) {
      console.error('Error updating password:', error);
      setError(error.message);
    }
  };

  return (
    <div className={styles.modal} onClick={closeModal}>
      <div
        className={styles.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.modalHeader}>
          <h2 className={styles.sectionTitle}>Change Password</h2>
          <button className={styles.closeButton} onClick={closeModal}>
            ×
          </button>
        </div>
        {error && <div className={styles.error}>{error}</div>}
        {success && <div className={styles.success}>{success}</div>}
        <form className={styles.form} onSubmit={handleSubmit}>
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <button type="submit">Update Password</button>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
