import React, { useState } from 'react';
import { supabase } from '../config/supabaseClient';
import styles from '../pages/Contacts.module.css';

const CustomerModal = ({ customer, closeModal, onSuccess }) => {
  const [formData, setFormData] = useState(
    customer || {
      customer_code: '',
      name: '',
      abbr_name: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      tel: '',
      fax: '',
      pay_term: '',
      port: '',
      box_info: '',
      active_yn: true
    }
  );
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      if (customer) {
        // Update existing customer
        const { error } = await supabase
          .from('customers')
          .update(formData)
          .eq('id', customer.id);

        if (error) throw error;
        setSuccess('Customer updated successfully');
      } else {
        // Add new customer
        const { error } = await supabase
          .from('customers')
          .insert([formData]);

        if (error) throw error;
        setSuccess('Customer added successfully');
      }

      setTimeout(() => {
        onSuccess();
      }, 1500);
    } catch (error) {
      console.error('Error saving customer:', error);
      setError(error.message);
    }
  };

  return (
    <div className={styles.modal} onClick={closeModal}>
      <div
        className={styles.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.modalHeader}>
          <h2 className={styles.sectionTitle}>
            {customer ? 'Edit Customer' : 'Add Customer'}
          </h2>
          <button className={styles.closeButton} onClick={closeModal}>
            ×
          </button>
        </div>
        {error && <div className={styles.error}>{error}</div>}
        {success && <div className={styles.success}>{success}</div>}
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formGrid}>
            <div className={styles.formField}>
              <label>Customer Code</label>
              <input
                type="text"
                value={formData.customer_code}
                onChange={(e) =>
                  setFormData({ ...formData, customer_code: e.target.value })
                }
                required
              />
            </div>
            <div className={styles.formField}>
              <label>Name</label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                required
              />
            </div>
            <div className={styles.formField}>
              <label>Abbreviated Name</label>
              <input
                type="text"
                value={formData.abbr_name}
                onChange={(e) =>
                  setFormData({ ...formData, abbr_name: e.target.value })
                }
              />
            </div>
            <div className={styles.formField}>
              <label>Street</label>
              <input
                type="text"
                value={formData.street}
                onChange={(e) =>
                  setFormData({ ...formData, street: e.target.value })
                }
              />
            </div>
            <div className={styles.formField}>
              <label>City</label>
              <input
                type="text"
                value={formData.city}
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
              />
            </div>
            <div className={styles.formField}>
              <label>State</label>
              <input
                type="text"
                value={formData.state}
                onChange={(e) =>
                  setFormData({ ...formData, state: e.target.value })
                }
              />
            </div>
            <div className={styles.formField}>
              <label>ZIP</label>
              <input
                type="text"
                value={formData.zip}
                onChange={(e) =>
                  setFormData({ ...formData, zip: e.target.value })
                }
              />
            </div>
            <div className={styles.formField}>
              <label>Country</label>
              <input
                type="text"
                value={formData.country}
                onChange={(e) =>
                  setFormData({ ...formData, country: e.target.value })
                }
              />
            </div>
            <div className={styles.formField}>
              <label>Telephone</label>
              <input
                type="text"
                value={formData.tel}
                onChange={(e) =>
                  setFormData({ ...formData, tel: e.target.value })
                }
              />
            </div>
            <div className={styles.formField}>
              <label>Fax</label>
              <input
                type="text"
                value={formData.fax}
                onChange={(e) =>
                  setFormData({ ...formData, fax: e.target.value })
                }
              />
            </div>
            <div className={styles.formField}>
              <label>Payment Terms</label>
              <input
                type="text"
                value={formData.pay_term}
                onChange={(e) =>
                  setFormData({ ...formData, pay_term: e.target.value })
                }
              />
            </div>
            <div className={styles.formField}>
              <label>Port</label>
              <input
                type="text"
                value={formData.port}
                onChange={(e) =>
                  setFormData({ ...formData, port: e.target.value })
                }
              />
            </div>
            <div className={styles.formField}>
              <label>Box Info</label>
              <input
                type="text"
                value={formData.box_info}
                onChange={(e) =>
                  setFormData({ ...formData, box_info: e.target.value })
                }
              />
            </div>
            <div className={styles.formField}>
              <label>Active</label>
              <select
                value={formData.active_yn}
                onChange={(e) =>
                  setFormData({ ...formData, active_yn: e.target.value === 'true' })
                }
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
          <button type="submit" className={styles.submitButton}>
            {customer ? 'Update Customer' : 'Add Customer'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CustomerModal;
