import React, { useState } from 'react';
import styles from './Users.module.css';
import { supabase, supabaseAdmin } from '../config/supabaseClient';

const AddUserModal = ({ closeModal, refreshUsers }) => {
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    password: '',
    role: 'user',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!newUser.username || !newUser.email || !newUser.password) {
      setError('Please fill in all fields');
      return;
    }

    try {
      const { data: existingUser } = await supabase
        .from('user')
        .select('*')
        .eq('email', newUser.email)
        .single();

      if (existingUser) {
        setError('User with this email already exists');
        return;
      }

      const { data: authData, error: authError } = await supabaseAdmin.auth.admin.createUser({
        email: newUser.email,
        password: newUser.password,
        email_confirm: true,
        user_metadata: {
          username: newUser.username,
          role: newUser.role
        }
      });

      if (authError) throw authError;

      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const { error: updateError } = await supabase
        .from('user')
        .update({ role: newUser.role })
        .eq('auth_id', authData.user.id);

      if (updateError) throw updateError;

      setSuccess('User created successfully');
      setNewUser({ username: '', email: '', password: '', role: 'user' });
      refreshUsers();
      setTimeout(() => {
        closeModal();
      }, 1500);
    } catch (error) {
      console.error('Error creating user:', error);
      setError(error.message);
      
      try {
        const { data: existingUser } = await supabase
          .from('user')
          .select('auth_id')
          .eq('email', newUser.email)
          .single();
        
        if (existingUser?.auth_id) {
          await supabaseAdmin.auth.admin.deleteUser(existingUser.auth_id);
        }
      } catch (cleanupError) {
        console.error('Error cleaning up:', cleanupError);
      }
    }
  };

  return (
    <div className={styles.modal} onClick={closeModal}>
      <div
        className={styles.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.modalHeader}>
          <h2 className={styles.sectionTitle}>Add New User</h2>
          <button className={styles.closeButton} onClick={closeModal}>
            ×
          </button>
        </div>
        {error && <div className={styles.error}>{error}</div>}
        {success && <div className={styles.success}>{success}</div>}
        <form className={styles.form} onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Username"
            value={newUser.username}
            onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
          />
          <input
            type="email"
            placeholder="Email"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
          <input
            type="password"
            placeholder="Password"
            value={newUser.password}
            onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
          />
          <select
            value={newUser.role}
            onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
          >
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
          <button type="submit">Add User</button>
        </form>
      </div>
    </div>
  );
};

export default AddUserModal;
