import React, { useState, useEffect } from 'react';
import { supabase } from '../config/supabaseClient';
import { useAuth } from '../contexts/AuthContext';
import styles from './Contacts.module.css';

const Vendors = () => {
  const [vendors, setVendors] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    state: 'all',
    country: 'all',
    active: 'all'
  });
  const [sortConfig, setSortConfig] = useState({
    key: 'vendor_code',
    direction: 'asc'
  });
  const { user } = useAuth();
  const isAdmin = user?.role === 'admin';

  useEffect(() => {
    fetchVendors();
  }, []);

  useEffect(() => {
    applyFiltersAndSearch();
  }, [vendors, searchTerm, filters, sortConfig]);

  const fetchVendors = async () => {
    try {
      console.log('Fetching vendors...');
      const { data, error } = await supabase
        .from('vendors')
        .select('*')
        .order('vendor_code', { ascending: true });

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }
      console.log('Fetched vendors:', data);
      setVendors(data || []);
    } catch (error) {
      console.error('Error fetching vendors:', error);
      setError('Failed to load vendors');
    } finally {
      setLoading(false);
    }
  };

  const applyFiltersAndSearch = () => {
    console.log('Applying filters with:', { searchTerm, filters, sortConfig });
    let result = [...vendors];

    // Apply search
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      result = result.filter(vendor =>
        vendor.name.toLowerCase().includes(searchLower) ||
        vendor.vendor_code.toLowerCase().includes(searchLower) ||
        vendor.city?.toLowerCase().includes(searchLower) ||
        vendor.state?.toLowerCase().includes(searchLower)
      );
    }

    // Apply filters
    if (filters.state !== 'all') {
      result = result.filter(vendor => vendor.state === filters.state);
    }
    if (filters.country !== 'all') {
      result = result.filter(vendor => vendor.country === filters.country);
    }
    if (filters.active !== 'all') {
      result = result.filter(vendor => 
        vendor.active_yn === (filters.active === 'active')
      );
    }

    // Apply sorting
    result.sort((a, b) => {
      const aVal = a[sortConfig.key] || '';
      const bVal = b[sortConfig.key] || '';
      if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredVendors(result);
  };

  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleBulkDelete = async () => {
    if (!isAdmin || !selectedVendors.length) return;
    
    if (window.confirm(`Are you sure you want to delete ${selectedVendors.length} vendors?`)) {
      try {
        const { error } = await supabase
          .from('vendors')
          .delete()
          .in('id', selectedVendors);

        if (error) throw error;
        
        fetchVendors();
        setSelectedVendors([]);
      } catch (error) {
        console.error('Error deleting vendors:', error);
        setError('Failed to delete vendors');
      }
    }
  };

  const handleBulkExport = () => {
    const selectedData = vendors.filter(v => selectedVendors.includes(v.id));
    const csv = convertToCSV(selectedData);
    downloadCSV(csv, 'vendors_export.csv');
  };

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]).join(',');
    const rows = data.map(obj => Object.values(obj).join(','));
    return [headers, ...rows].join('\n');
  };

  const downloadCSV = (csv, filename) => {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedVendors(filteredVendors.map(v => v.id));
    } else {
      setSelectedVendors([]);
    }
  };

  const handleSelectVendor = (vendorId) => {
    setSelectedVendors(prev => {
      if (prev.includes(vendorId)) {
        return prev.filter(id => id !== vendorId);
      } else {
        return [...prev, vendorId];
      }
    });
  };

  const getUniqueValues = (key) => {
    return [...new Set(vendors.map(v => v[key]).filter(Boolean))].sort();
  };

  const renderFilters = () => (
    <div className={styles.filters}>
      <div className={styles.searchBox}>
        <input
          type="text"
          placeholder="Search vendors..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={styles.searchInput}
        />
      </div>
      <select
        value={filters.state}
        onChange={(e) => setFilters(prev => ({ ...prev, state: e.target.value }))}
        className={styles.filterSelect}
      >
        <option value="all">All States</option>
        {getUniqueValues('state').map(state => (
          <option key={state} value={state}>{state}</option>
        ))}
      </select>
      <select
        value={filters.country}
        onChange={(e) => setFilters(prev => ({ ...prev, country: e.target.value }))}
        className={styles.filterSelect}
      >
        <option value="all">All Countries</option>
        {getUniqueValues('country').map(country => (
          <option key={country} value={country}>{country}</option>
        ))}
      </select>
      <select
        value={filters.active}
        onChange={(e) => setFilters(prev => ({ ...prev, active: e.target.value }))}
        className={styles.filterSelect}
      >
        <option value="all">All Status</option>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </select>
    </div>
  );

  const renderBulkActions = () => (
    <div className={styles.bulkActions}>
      <span>{selectedVendors.length} selected</span>
      <button
        className={styles.bulkActionButton}
        onClick={handleBulkExport}
        disabled={!selectedVendors.length}
      >
        Export Selected
      </button>
      {isAdmin && (
        <button
          className={`${styles.bulkActionButton} ${styles.deleteButton}`}
          onClick={handleBulkDelete}
          disabled={!selectedVendors.length}
        >
          Delete Selected
        </button>
      )}
    </div>
  );

  const renderVendorTable = () => (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedVendors.length === filteredVendors.length}
              />
            </th>
            <th onClick={() => handleSort('vendor_code')} className={styles.sortable}>
              Code {sortConfig.key === 'vendor_code' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('name')} className={styles.sortable}>
              Name {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('city')} className={styles.sortable}>
              City {sortConfig.key === 'city' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('state')} className={styles.sortable}>
              State {sortConfig.key === 'state' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('country')} className={styles.sortable}>
              Country {sortConfig.key === 'country' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th>Tel</th>
            <th>Pay Term</th>
            <th onClick={() => handleSort('active_yn')} className={styles.sortable}>
              Status {sortConfig.key === 'active_yn' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredVendors.map((vendor) => (
            <tr key={vendor.id} className={styles.tableRow}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedVendors.includes(vendor.id)}
                  onChange={() => handleSelectVendor(vendor.id)}
                />
              </td>
              <td>{vendor.vendor_code}</td>
              <td>{vendor.name}</td>
              <td>{vendor.city}</td>
              <td>{vendor.state}</td>
              <td>{vendor.country}</td>
              <td>{vendor.tel1}</td>
              <td>{vendor.pay_term}</td>
              <td>
                <span className={vendor.active_yn ? styles.activeStatus : styles.inactiveStatus}>
                  {vendor.active_yn ? 'Active' : 'Inactive'}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <>
      {loading ? (
        <div className={styles.loading}>Loading...</div>
      ) : error ? (
        <div className={styles.error}>{error}</div>
      ) : (
        <>
          {renderFilters()}
          {renderBulkActions()}
          {renderVendorTable()}
        </>
      )}
    </>
  );
};

export default Vendors;
