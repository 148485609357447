import React, { useState, useEffect } from 'react';
import { supabase } from '../config/supabaseClient';
import { useAuth } from '../contexts/AuthContext';
import styles from './Contacts.module.css';
import CustomerModal from '../components/CustomerModal';
import VendorModal from '../components/VendorModal';
import Vendors from './Vendors';

const Contacts = () => {
  const [activeType, setActiveType] = useState('customer');
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editCustomer, setEditCustomer] = useState(null);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    state: 'all',
    country: 'all',
    active: 'all'
  });
  const [sortConfig, setSortConfig] = useState({
    key: 'customer_code',
    direction: 'asc'
  });
  const [showAddOptions, setShowAddOptions] = useState(false);
  const [modalType, setModalType] = useState(null);
  const { user } = useAuth();
  const isAdmin = user?.role === 'admin';

  useEffect(() => {
    if (activeType === 'customer') {
      fetchCustomers();
    }
  }, [activeType]);

  useEffect(() => {
    applyFiltersAndSearch();
  }, [customers, searchTerm, filters, sortConfig]);

  const fetchCustomers = async () => {
    try {
      const { data, error } = await supabase
        .from('customers')
        .select('*')
        .order('customer_code', { ascending: true });

      if (error) throw error;
      setCustomers(data || []);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError('Failed to load customers');
    } finally {
      setLoading(false);
    }
  };

  const applyFiltersAndSearch = () => {
    let result = [...customers];

    // Apply search
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      result = result.filter(customer =>
        customer.name.toLowerCase().includes(searchLower) ||
        customer.customer_code.toLowerCase().includes(searchLower) ||
        customer.city?.toLowerCase().includes(searchLower) ||
        customer.state?.toLowerCase().includes(searchLower)
      );
    }

    // Apply filters
    if (filters.state !== 'all') {
      result = result.filter(customer => customer.state === filters.state);
    }
    if (filters.country !== 'all') {
      result = result.filter(customer => customer.country === filters.country);
    }
    if (filters.active !== 'all') {
      result = result.filter(customer => 
        customer.active_yn === (filters.active === 'active')
      );
    }

    // Apply sorting
    result.sort((a, b) => {
      const aVal = a[sortConfig.key] || '';
      const bVal = b[sortConfig.key] || '';
      if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredCustomers(result);
  };

  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleEdit = (customer) => {
    if (!isAdmin) return;
    setEditCustomer(customer);
    setModalType('customer');
    setShowAddModal(true);
  };

  const handleAdd = (type) => {
    if (!isAdmin) return;
    setEditCustomer(null);
    setModalType(type);
    setShowAddModal(true);
    setShowAddOptions(false);
  };

  const handleBulkDelete = async () => {
    if (!isAdmin || !selectedCustomers.length) return;
    
    if (window.confirm(`Are you sure you want to delete ${selectedCustomers.length} customers?`)) {
      try {
        const { error } = await supabase
          .from('customers')
          .delete()
          .in('id', selectedCustomers);

        if (error) throw error;
        
        fetchCustomers();
        setSelectedCustomers([]);
      } catch (error) {
        console.error('Error deleting customers:', error);
        setError('Failed to delete customers');
      }
    }
  };

  const handleBulkExport = () => {
    const selectedData = customers.filter(c => selectedCustomers.includes(c.id));
    const csv = convertToCSV(selectedData);
    downloadCSV(csv, 'customers_export.csv');
  };

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]).join(',');
    const rows = data.map(obj => Object.values(obj).join(','));
    return [headers, ...rows].join('\n');
  };

  const downloadCSV = (csv, filename) => {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedCustomers(filteredCustomers.map(c => c.id));
    } else {
      setSelectedCustomers([]);
    }
  };

  const handleSelectCustomer = (customerId) => {
    setSelectedCustomers(prev => {
      if (prev.includes(customerId)) {
        return prev.filter(id => id !== customerId);
      } else {
        return [...prev, customerId];
      }
    });
  };

  const getUniqueValues = (key) => {
    return [...new Set(customers.map(c => c[key]).filter(Boolean))].sort();
  };

  const renderFilters = () => (
    <div className={styles.filters}>
      <div className={styles.searchBox}>
        <input
          type="text"
          placeholder="Search customers..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={styles.searchInput}
        />
      </div>
      <select
        value={filters.state}
        onChange={(e) => setFilters(prev => ({ ...prev, state: e.target.value }))}
        className={styles.filterSelect}
      >
        <option value="all">All States</option>
        {getUniqueValues('state').map(state => (
          <option key={state} value={state}>{state}</option>
        ))}
      </select>
      <select
        value={filters.country}
        onChange={(e) => setFilters(prev => ({ ...prev, country: e.target.value }))}
        className={styles.filterSelect}
      >
        <option value="all">All Countries</option>
        {getUniqueValues('country').map(country => (
          <option key={country} value={country}>{country}</option>
        ))}
      </select>
      <select
        value={filters.active}
        onChange={(e) => setFilters(prev => ({ ...prev, active: e.target.value }))}
        className={styles.filterSelect}
      >
        <option value="all">All Status</option>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </select>
    </div>
  );

  const renderBulkActions = () => (
    <div className={styles.bulkActions}>
      <span>{selectedCustomers.length} selected</span>
      <button
        className={styles.bulkActionButton}
        onClick={handleBulkExport}
        disabled={!selectedCustomers.length}
      >
        Export Selected
      </button>
      {isAdmin && (
        <button
          className={`${styles.bulkActionButton} ${styles.deleteButton}`}
          onClick={handleBulkDelete}
          disabled={!selectedCustomers.length}
        >
          Delete Selected
        </button>
      )}
    </div>
  );

  const renderCustomerTable = () => (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedCustomers.length === filteredCustomers.length}
              />
            </th>
            <th onClick={() => handleSort('customer_code')} className={styles.sortable}>
              Code {sortConfig.key === 'customer_code' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('name')} className={styles.sortable}>
              Name {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('city')} className={styles.sortable}>
              City {sortConfig.key === 'city' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('state')} className={styles.sortable}>
              State {sortConfig.key === 'state' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('country')} className={styles.sortable}>
              Country {sortConfig.key === 'country' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th>Tel</th>
            <th>Pay Term</th>
            <th onClick={() => handleSort('active_yn')} className={styles.sortable}>
              Status {sortConfig.key === 'active_yn' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            {isAdmin && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {filteredCustomers.map((customer) => (
            <tr key={customer.id} className={styles.tableRow}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedCustomers.includes(customer.id)}
                  onChange={() => handleSelectCustomer(customer.id)}
                />
              </td>
              <td>{customer.customer_code}</td>
              <td>{customer.name}</td>
              <td>{customer.city}</td>
              <td>{customer.state}</td>
              <td>{customer.country}</td>
              <td>{customer.tel}</td>
              <td>{customer.pay_term}</td>
              <td>
                <span className={customer.active_yn ? styles.activeStatus : styles.inactiveStatus}>
                  {customer.active_yn ? 'Active' : 'Inactive'}
                </span>
              </td>
              {isAdmin && (
                <td>
                  <button
                    className={styles.editButton}
                    onClick={() => handleEdit(customer)}
                  >
                    Edit
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>Contact Management</h2>
        <div className={styles.headerRight}>
          <button
            className={`${styles.viewButton} ${activeType === 'customer' ? styles.active : ''}`}
            onClick={() => setActiveType('customer')}
          >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
              <circle cx="9" cy="7" r="4"></circle>
              <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
            <span className={styles.buttonText}>Customers</span>
          </button>
          <button
            className={`${styles.viewButton} ${activeType === 'vendor' ? styles.active : ''}`}
            onClick={() => setActiveType('vendor')}
          >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
              <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
              <line x1="12" y1="22.08" x2="12" y2="12"></line>
            </svg>
            <span className={styles.buttonText}>Vendors</span>
          </button>
          {isAdmin && (
            <div className={styles.addButtonContainer}>
              <button 
                className={styles.addButton} 
                onClick={() => setShowAddOptions(!showAddOptions)}
              >
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                <span className={styles.buttonText}>Add Contact</span>
              </button>
              {showAddOptions && (
                <div className={styles.addOptions}>
                  <button onClick={() => handleAdd('customer')}>Add Customer</button>
                  <button onClick={() => handleAdd('vendor')}>Add Vendor</button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className={styles.contentSection}>
        {loading && activeType === 'customer' ? (
          <div className={styles.loading}>Loading...</div>
        ) : error ? (
          <div className={styles.error}>{error}</div>
        ) : activeType === 'customer' ? (
          <>
            {renderFilters()}
            {renderBulkActions()}
            {renderCustomerTable()}
          </>
        ) : (
          <Vendors />
        )}
      </div>

      {showAddModal && modalType === 'customer' && (
        <CustomerModal
          customer={editCustomer}
          closeModal={() => {
            setShowAddModal(false);
            setEditCustomer(null);
            setModalType(null);
          }}
          onSuccess={() => {
            fetchCustomers();
            setShowAddModal(false);
            setEditCustomer(null);
            setModalType(null);
          }}
        />
      )}

      {showAddModal && modalType === 'vendor' && (
        <VendorModal
          vendor={null}
          closeModal={() => {
            setShowAddModal(false);
            setModalType(null);
          }}
          onSuccess={() => {
            setShowAddModal(false);
            setModalType(null);
          }}
        />
      )}
    </div>
  );
};

export default Contacts;
